@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body{
  background-color: #F3F3F1;
}

body {
  background: #fff;
  color: #000;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: 16px;
  line-height: 28px;
  margin: 0
}

h1 {
  font-size: 40px;
  line-height: 60px
}

h1,h2 {
  font-weight: 700
}

h2 {
  font-size: 32px;
  line-height: 48px
}

h3 {
  font-size: 24px;
  line-height: 36px
}

h3,h4 {
  font-weight: 700
}

h4 {
  font-size: 20px;
  line-height: 30px
}

h5 {
  font-size: 16px
}

h5,h6 {
  line-height: 24px;
  font-weight: 700
}

h6 {
  font-size: cacl(16px)
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #000
}

a:hover,a[rel~=nofollow] {
  text-decoration: underline
}

a[rel~=nofollow] {
  color: #0452a5
}

a[rel~=nofollow]:hover {
  text-decoration: none
}

.visible {
  display: block
}

.hidden {
  display: none
}

.page {
  width: 100%
}

.container {
  position: relative;
  width: 90%;
  max-width: 1024px;
  margin: 0 auto
}

.header {
  color: #000;
  padding: 16px 0
}

.header .title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin: 0
}

.translations-list-container {
  color: #000;
  padding-bottom: 8px;
  margin: 0 0 16px
}

.translations-list-container .translations-list {
  margin: 0;
  padding: 0;
  list-style: none
}

.translations-list-container .translations-list .translations-list-item {
  display: inline-block;
  padding: 0;
  margin: 0 8px 8px 0;
  color: #fff
}

.translations-list-container .translations-list .translations-list-item a {
  display: inline-block;
  padding: 4px 8px
}

.translations-list-container .translations-list .translations-list-item a.active {
  color: #fff;
  background: #334055
}

.translations-content-container {
  padding-top: 8px;
  border-top: 1px solid #eee
}

.footer {
  border-top: 1px solid #eee;
  margin: 32px 0 0;
  padding: 16px 0
}

* {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 transparent; /* For Firefox */
}

/* For Webkit-based browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 6px; /* Height of the scrollbar for horizontal scrolling */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scroll thumb */
  border-radius: 10px; /* Rounded corners for the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker shade when hovering */
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.category-list {
  overflow: scroll; /* Enables scrolling */
  scrollbar-width: none; /* For Firefox */
}

/* For Webkit-based browsers (Chrome, Safari, Edge) */
.category-list::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

.category-list {
  overflow-x: auto; /* Enables horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scroll within the element */
  white-space: nowrap; /* Ensures content doesn't wrap to a new line */
}

.category-list::-webkit-scrollbar {
  display: none; /* Hides scrollbar for WebKit-based browsers */
}